import { FunctionComponent, Suspense, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { lazy } from "@loadable/component";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";

import { getConfigs } from "@ducks/config";
import { initUlmUuid, initUlmHandlerTriggered } from "@ducks/ulm";
import { disneyActivatePath } from "@constants/paths";
import settings from "@settings";
import { AppState, history } from "@store";
import defaultTheme from "@theme/default";

import NavProvider from "acm-components/module/layouts/Navigation/NavProvider";
import Header from "@components/Header";
import BaseMeta from "@components/BaseMeta";
import LiveChatWidget from "@components/LiveChatWidget";
import ScrollToTop from "@components/ScrollToTop";
import ShopError from "@components/ShopError";
import SuspenseFallback from "@components/SuspenseFallback";

const Footer = lazy(() => import(/* webpackChunkName: "footer" */ "acm-components/module/layouts/Navigation/Footer"));
const LandingRedirection = lazy(() => import(/* webpackChunkName: "landingRedirection" */ "@pages/LandingRedirection"));
const AppsRouting = lazy(() => import(/* webpackChunkName: "appsRouting" */ "./AppsRouting"));

const App: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isUnderMaintenance = useSelector<AppState, boolean>((state) => state.config.app.maintenanceMode);

  useEffect(() => {
    dispatch(getConfigs());
    dispatch(initUlmUuid());
    dispatch(initUlmHandlerTriggered());
  }, [dispatch]);

  return (
    <MuiThemeProvider theme={defaultTheme}>
      {/* CSS reset */}
      <CssBaseline />

      <NavProvider environment={settings.navEnv}>
        {/* ACM Header */}
        <Header />

        {/* Allow fallback dom when lazy pages are loading */}
        <Suspense fallback={<SuspenseFallback />}>
          <main>
            {/* Base meta tags */}
            <BaseMeta />

            {/* Pages */}
            {isUnderMaintenance ? (
              <ShopError errorCode="MAINTENANCE" />
            ) : (
              <ConnectedRouter history={history}>
                <ScrollToTop />
                <Switch>
                  <Route path={`/${disneyActivatePath}`}>
                    <AppsRouting />
                  </Route>
                  <Route exact path="/">
                    <LandingRedirection />
                  </Route>
                  <Route path="*">
                    <ShopError errorCode="NOT_FOUND" />
                  </Route>
                </Switch>
              </ConnectedRouter>
            )}
          </main>

          {/* ACM Footer */}
          <Footer />
        </Suspense>

        {/* ACM Live Chat */}
        <LiveChatWidget />
      </NavProvider>
    </MuiThemeProvider>
  );
};

App.displayName = "App";

export default App;
